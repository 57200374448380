import React from 'react';
import './CallToAction.css';
// import { Link } from 'react-router-dom';

const CallToAction = () => {
  return (
    <div className="cta-section">
      
      <a href="https://com.rpy.club/pdp/capcutcrashcourse?plan=670e3c55eb71a1300925d4a6" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
      <button className="cta-button">
        Join Now and Start Editing Like a Pro!
      </button>
    </a>
      <br></br>
      <br></br>
      <p>
      Claim Your FREE Bonuses Worth ₹2,500!
      </p>
    </div>
  );
};

export default CallToAction;
